import { BasicLoanDto, BasicLoanRoleDto, BorrowUserSetDto, DeepLinkActionRequestDto, FormElementsV2ResponseDto, FormElementUrlResponseDto, FormElementV2ResponseDto, LeadUserSetDto, LinkActionInviteToLoanResponseDto, LoanDto, LoanPhaseCategoryType, LoanRoleDto, NewLoanDTO, SearchResultsDTO, UpdateLoanDTO } from 'src/backend';
import { elementsTree } from 'src/slices/elementv2';
import { TemplateElementTree } from 'src/types/formelement';
import { Loan } from 'src/types/loan';
import { transformLoan } from 'src/utils/loan/transform-loan';

import { baseApi } from './baseApi';

export interface LoanDtoExtended extends LoanDto {
    closeDate: string,
    loanProgress: number,
    closeDateDistance,
    closeDateYearFormatted: string,
    createdDateYearFormatted: string,
    closeDateFormatted: string,
    createdDateFormatted: string,
    closeDateIsPassed: boolean,
    borrowers: LoanDto['borrowers'],
    borrowerTeamUsers: LoanDto['borrowerTeamUsers'],
    templateProjectName: string,
}

export interface BasicLoanRoleDtoExtended extends BasicLoanRoleDto {
    loan: string;
}

export interface BasicLoanDtoExtended extends BasicLoanDto {
    loanRoles: BasicLoanRoleDtoExtended[],
}

export const loanApi = baseApi.enhanceEndpoints({ addTagTypes: ["ContactSubRoleDto", "ContactRelationDto", "LinkActionInviteToLoanResponseDto", 'SearchResultsDTO', 'FormElementsV2ResponseDto', 'BasicLoanDto', 'LoanDto'] }).injectEndpoints({
    endpoints: (build) => ({
        getLoansForCompanyAndUser: build.query<BasicLoanDtoExtended[], { companyId: string, userId: string }>({
            query: (data) => ({
                url: `/v1/loans/company/${data.companyId}/user/${data.userId}`,
                method: 'GET',
            }),
            providesTags: (res) => [
                ...res.map<{ type: 'BasicLoanDto', id: string }>(({ id }) => ({ type: 'BasicLoanDto', id })),
                {
                    type: 'BasicLoanDto',
                    id: 'LIST'
                }],
            transformResponse: (response: BasicLoanDto[]) => response.map(loan => ({
                ...loan,
                loanRoles: loan.loanRoles.map((role) => ({
                    ...role,
                    loan: loan.id
                }))
            })),
        }),
        addLender: build.mutation<LoanDto, { loanId: string, lenderUser: LeadUserSetDto }>({
            query: (data) => ({
                url: `/v1/loans/${data.loanId}/lenderUser`,
                method: 'POST',
                data: data.lenderUser,
            }),
            invalidatesTags: (_, _2, { loanId }) => [
                { type: 'BasicLoanDto', id: loanId },
                { type: 'LoanDto', id: loanId },
                'BasicLoanDto'
            ]
        }),
        editLender: build.mutation<LoanDto, { loanId: string, lenderUser: LeadUserSetDto }>({
            query: (data) => ({
                url: `/v1/loans/${data.loanId}/lenderUser`,
                method: 'PUT',
                data: data.lenderUser,
            }),
            invalidatesTags: (_, _2, { loanId }) => [
                { type: 'BasicLoanDto', id: loanId },
                { type: 'LoanDto', id: loanId },
                'BasicLoanDto'
            ]
        }),
        pinLoan: build.mutation<BasicLoanDto, { loanId: string }>({
            query: (data) => ({
                url: `/v1/loans/${data.loanId}/pin`,
                method: 'PUT',
            }),
            invalidatesTags: (_, _2, { loanId }) => [{ type: 'BasicLoanDto', id: loanId },
                'BasicLoanDto']
        }),
        unPinLoan: build.mutation<BasicLoanDto, { loanId: string }>({
            query: (data) => ({
                url: `/v1/loans/${data.loanId}/unpin`,
                method: 'PUT',
            }),
            invalidatesTags: (_, _2, { loanId }) => [
                { type: 'BasicLoanDto', id: loanId },
                'BasicLoanDto']
        }),
        getLoansView: build.query<Loan[], { categories?: LoanPhaseCategoryType[]; }>({
            query: () => ({
                url: `/v1/loans/view`,
                method: 'GET',
            }),
            transformResponse: (response: LoanDto[]) => {

                return response.map(transformLoan);
            },
            providesTags: ['BasicLoanDto']
        }),
        getLoanFolders: build.query<TemplateElementTree<FormElementV2ResponseDto>[], string>({
            query: (id) => ({
                url: `/v2/loanmanager/${id}/folders`,
                method: 'GET',
            }),
            providesTags: (_err, _res, _arg) => [{ type: 'FormElementsV2ResponseDto', id: _arg }],
            transformResponse: (response: FormElementsV2ResponseDto) => {
                const elements = Object
                    .values(response.packageInfo)
                    .map((packageInfo) => ({
                        ...response['elements'][packageInfo.elementId],
                        id: packageInfo.id,
                        parentId: packageInfo.parentInfoId ?? null,
                        elementId: packageInfo.elementId,
                        childrenIds: packageInfo.childrenIds,
                        title: packageInfo.title,
                        priorityType: packageInfo.priorityType,
                    }));

                const parentId = elements.find((folder) => !folder.parentId)?.id;

                if (!elements.length || !parentId) return [];

                const tree = elementsTree<FormElementV2ResponseDto>(elements, 'id', [parentId]);
                return tree;
            }
        }),
        getLoanById: build.query<LoanDtoExtended, string>({
            query: (id) => ({
                url: `/v1/loans/${id}`,
                method: 'GET',
            }),
            providesTags: (_result, _error, id) => [{
                type: 'LoanDto',
                id: id
            }],
            transformResponse: transformLoan,
        }),
        createLoan: build.mutation<Loan, NewLoanDTO>({
            query: (data) => ({
                url: `/v2/loanmanager/createFromTemplate`,
                method: 'POST',
                data
            }),
            invalidatesTags: (result, _2, _3) => [{ type: 'LoanDto', id: result.id }, 'BasicLoanDto'],
        }),
        updateLoan: build.mutation<Loan, { id: string, payload: UpdateLoanDTO }>({
            query: (data) => ({
                url: `/v1/loans/${data.id}`,
                method: 'PUT',
                data: data.payload
            }),
            invalidatesTags: (_1, _2, { id }) => [{ type: 'LoanDto', id }, 'BasicLoanDto'],
        }),
        exportToSharepoint: build.mutation<void, { loanId: string }>({
            query: (data) => ({
                url: `/v2/sharepoint/export/${data.loanId}`,
                method: 'POST',
            }),
        }),
        getDeepLinkActionForObject: build.query<string, { forId: string, url: string, companyId?: string }>({
            query: (data) => ({
                url: `/v2/loanmanager/deepLinkActionForLoan`,
                data,
                method: 'GET',
            }),
            transformResponse: (response: FormElementUrlResponseDto) => response.url,
        }),
        createDeepLinkActionForObject: build.mutation<FormElementUrlResponseDto, DeepLinkActionRequestDto>({
            query: (data) => ({
                url: `/v2/loanmanager/createDeepLinkActionForLoan`,
                data,
                method: 'POST',
            }),
        }),
        getPendingInvites: build.query<LinkActionInviteToLoanResponseDto[], void>({
            query: () => ({
                url: `/v1/action/pendingInvites`,
                method: 'GET',
            }),
            providesTags: ['LinkActionInviteToLoanResponseDto']
        }),
        searchLoans: build.query<SearchResultsDTO, { q: string; p?: number; d?: string; c?: string; ot?: string; }>({
            query: (data) => ({
                url: `/v1/search`,
                method: 'GET',
                data
            }),
            providesTags: ['SearchResultsDTO']
        }),
        editNonLenderUser: build.mutation<LoanDto, { loanId: string, userId: string, iDto: Partial<BorrowUserSetDto> }>({
            query: (data) => ({
                url: `/v1/loans/editNonLenderUser/${data.loanId}/${data.userId}`,
                method: 'POST',
                data: data.iDto,
            }),
            invalidatesTags: (_, _2, { loanId }) => [
                { type: 'BasicLoanDto', id: loanId },
                { type: 'LoanDto', id: loanId },
                'BasicLoanDto',
                'ContactSubRoleDto',
                'ContactRelationDto'
            ]
        }),
    }),
    overrideExisting: true,
})

export const {
    useEditNonLenderUserMutation,
    useSearchLoansQuery,
    useGetPendingInvitesQuery,
    useCreateDeepLinkActionForObjectMutation,
    useGetDeepLinkActionForObjectQuery,
    useLazyGetDeepLinkActionForObjectQuery,
    useGetLoansForCompanyAndUserQuery,
    usePinLoanMutation,
    useCreateLoanMutation,
    useUpdateLoanMutation,
    useUnPinLoanMutation,
    useLazyGetLoansForCompanyAndUserQuery,
    useGetLoansViewQuery,
    useGetLoanByIdQuery,
    useLazyGetLoanByIdQuery,
    useGetLoanFoldersQuery,
    useExportToSharepointMutation,
    useEditLenderMutation,
    useAddLenderMutation
} = loanApi;



